import React from 'react';
import { graphql } from 'gatsby';
import { DocumentsPage } from '../components/scenes/DocumentsPage/DocumentsPage';
import { SEO } from '../components/seo/SEO/SEO';


export default function Documents( { data } ) {
  return (
    <DocumentsPage data={ data } heading={ "Documents" }/>
  );
}

export function Head() {
  return (
    <SEO title={ 'Documents' } />
  );
}

export const query = graphql`
  query query {
    allMdx(
      filter: {fields: {version: {eq: "v4"}}}
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          fields {
            category
            language
            slug
            version
          }
          id
          frontmatter {
            title
            localizedTitle
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;